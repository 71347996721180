exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-characters-js": () => import("./../../../src/pages/characters.js" /* webpackChunkName: "component---src-pages-characters-js" */),
  "component---src-pages-font-js": () => import("./../../../src/pages/font.js" /* webpackChunkName: "component---src-pages-font-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-templates-character-js": () => import("./../../../src/templates/character.js" /* webpackChunkName: "component---src-templates-character-js" */),
  "component---src-templates-number-js": () => import("./../../../src/templates/number.js" /* webpackChunkName: "component---src-templates-number-js" */)
}


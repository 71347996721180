import { includes } from 'lodash'

export function shouldUpdateScroll({ pathname }) {
  const disableScrollPages = [
    '/zero',
    '/one',
    '/two',
    '/three',
    '/four',
    '/five',
    '/six',
    '/seven',
    '/eight',
    '/nine',
  ]
  return !includes(disableScrollPages, pathname);
}
